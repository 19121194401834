import { useEffect } from "react";

import MainNavbar from "components/Navbar/MainNavbar";
import HeroSection from "../components/Home/HeroSection";
import TestimonialsSection from "../components/Home/TestimonialsSection";
import FeaturesSection from "../components/Home/FeaturesSection";
import InvestorSection from "../components/Home/InvestorSection";
import FAQSection from "../components/Home/FAQSection";
import BottomCTASection from "../components/Home/BottomCTASection";
import IndexFooter from "../components/IndexFooter";


import useDeviceSpecificAppDownloadLink from "../hooks/useAppDownloadLink";
import useDeviceSpecificAppDownloadButton from "../hooks/useAppDownloadButton";
import { pageview } from "@qoohoo/common/constants/analytics";
import * as Track from "@qoohoo/common/utils/analytics/track";
import ExperienceSection from "../components/Home/ExperienceSection";
import ToolsSection from "../components/Home/ToolsSection";
import YCPageBanner from "../components/page-banner/YCPageBanner";

const currentScreen = pageview.home;

export default function Index() {
  const downloadLink = useDeviceSpecificAppDownloadLink();
  const { showAndroidButton, showIOSButton } =
    useDeviceSpecificAppDownloadButton();

  useEffect(() => {
    Track.pageView(currentScreen);
  }, []);

  return (
    <>
      <YCPageBanner />
      <MainNavbar currentScreen={currentScreen} showAuth isSticky/>
      <main>
        <HeroSection
          downloadLink={downloadLink}
          showAndroidButton={showAndroidButton}
          showIOSButton={showIOSButton}
        />
        <ToolsSection />
        <ExperienceSection />
        <TestimonialsSection />
        <FeaturesSection />
        <InvestorSection />
        <FAQSection />
        <BottomCTASection
          downloadLink={downloadLink}
          showAndroidButton={showAndroidButton}
          showIOSButton={showIOSButton}
        />
      </main>
      <IndexFooter />
    </>
  );
}
